/**
 * Enum containing different error states for a date component.
 */
export var DateErrorType;
(function (DateErrorType) {
    DateErrorType["INVALID_DATE"] = "INVALID_DATE";
    DateErrorType["INCOMPLETE_DATE"] = "INCOMPLETE_DATE";
    DateErrorType["UNDER_MIN"] = "UNDER_MIN";
    DateErrorType["OVER_MAX"] = "OVER_MAX";
    DateErrorType["NO_ERROR"] = "NO_ERROR";
})(DateErrorType || (DateErrorType = {}));
/**
 * Enum containing different error states for a date-range component.
 */
export var DateRangeErrorType;
(function (DateRangeErrorType) {
    DateRangeErrorType["FROM_AFTER_TO"] = "FROM_AFTER_TO";
    DateRangeErrorType["NOT_IN_RANGE"] = "NOT_IN_RANGE";
    DateRangeErrorType["NO_ERROR"] = "NO_ERROR";
})(DateRangeErrorType || (DateRangeErrorType = {}));
/**
 * Enum containing different error states for `<dx-date-time>` elements using time segments.
 */
export var TimeErrorType;
(function (TimeErrorType) {
    TimeErrorType["INVALID_TIME"] = "INVALID_TIME";
    TimeErrorType["INCOMPLETE_TIME"] = "INCOMPLETE_TIME";
    TimeErrorType["UNDER_MIN"] = "UNDER_MIN";
    TimeErrorType["OVER_MAX"] = "OVER_MAX";
    TimeErrorType["NO_ERROR"] = "NO_ERROR";
})(TimeErrorType || (TimeErrorType = {}));
/**
 * Enum containing different error states for `<dx-date-time>` elements using time segments.
 */
export var TimeRangeErrorType;
(function (TimeRangeErrorType) {
    TimeRangeErrorType["FROM_AFTER_TO"] = "FROM_AFTER_TO";
    TimeRangeErrorType["NOT_IN_RANGE"] = "NOT_IN_RANGE";
    TimeRangeErrorType["NO_ERROR"] = "NO_ERROR";
})(TimeRangeErrorType || (TimeRangeErrorType = {}));
